import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { accountLoggedOut, AUTHENTICATED_ROUTES } from "@finn/cp-utils";
import { clearClientCookie, CookieKeys } from "@finn/ui-utils";
import axios from "axios";
import { useRouter } from "next/router";
import { createContext, useContext } from "react";
import * as auth from "./auth";
import { checkAccountSwitch } from "./checkAccountSwitch";
export var AuthContext = createContext(_object_spread_props(_object_spread({}, auth), {
    disabled: [],
    provider: "ua"
}));
export var useSignOut = function() {
    var router = useRouter();
    var signOut = useContext(AuthContext).signOut;
    var handleSignOut = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
            var pathname, origin, ref, csrfToken;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return checkAccountSwitch();
                    case 2:
                        accountLoggedOut({});
                        clearClientCookie(CookieKeys.LAST_DEAL_INFO);
                        clearClientCookie(CookieKeys.TEMP_DELIVERY_DATE);
                        clearClientCookie(CookieKeys.TEMP_SWAP_DEAL_ID);
                        clearClientCookie(CookieKeys.RETURN_TO_CKO);
                        pathname = router.pathname;
                        if (!AUTHENTICATED_ROUTES.some(function(route) {
                            return pathname.includes(route);
                        })) {
                            _ctx.next = 24;
                            break;
                        }
                        _ctx.prev = 9;
                        origin = window === null || window === void 0 ? void 0 : window.origin;
                        _ctx.next = 13;
                        return axios.get("".concat(origin, "/api/auth/csrf"));
                    case 13:
                        ref = _ctx.sent;
                        csrfToken = ref.data.csrfToken;
                        _ctx.next = 17;
                        return axios.post("".concat(origin, "/api/auth/signout"), {
                            csrfToken: csrfToken,
                            callbackUrl: "".concat(origin, "/"),
                            json: true
                        });
                    case 17:
                        _ctx.next = 21;
                        break;
                    case 19:
                        _ctx.prev = 19;
                        _ctx.t0 = _ctx["catch"](9);
                    case 21:
                        // To prevent login prompt if user is logging out from a page which needs authentication
                        router.push("/home");
                        _ctx.next = 27;
                        break;
                    case 24:
                        _ctx.next = 26;
                        return signOut();
                    case 26:
                        // For user interaction purpose
                        router.reload();
                    case 27:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    9,
                    19
                ]
            ]);
        }));
        return function handleSignOut() {
            return _ref.apply(this, arguments);
        };
    }();
    var handleSignOutClick = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(event) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        event.preventDefault();
                        _ctx.next = 3;
                        return handleSignOut();
                    case 3:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function handleSignOutClick(event) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        handleSignOutClick: handleSignOutClick
    };
};
