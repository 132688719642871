import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { traceabilityHeadersBrowser, useSession } from "@finn/ui-utils";
import axios from "axios";
import useSWR from "swr";
var LOYALTY_INFO_ENDPOINT = "/api/getLoyaltyInfo";
var loyaltyInfoFetcher = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(contactId) {
        var response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    _ctx.next = 3;
                    return axios.post(LOYALTY_INFO_ENDPOINT, {
                        contactId: contactId
                    }, {
                        headers: traceabilityHeadersBrowser()
                    });
                case 3:
                    response = _ctx.sent;
                    return _ctx.abrupt("return", response.data);
                case 7:
                    _ctx.prev = 7;
                    _ctx.t0 = _ctx["catch"](0);
                    return _ctx.abrupt("return", null);
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                7
            ]
        ]);
    }));
    return function loyaltyInfoFetcher(contactId) {
        return _ref.apply(this, arguments);
    };
}();
var DEDUPING_INTERVAL = 300000;
// todo: remove contactId in the future as we'll take it from the session
// for now it's needed as some checkout page will use it for unauthenticated users
export var useGetLoyaltyInfo = function(contactId) {
    var ref = _sliced_to_array(useSession(), 1), session = ref[0];
    var ref1 = useSWR(session || contactId ? {
        key: "loyaltyInfo",
        contactId: contactId
    } : null, loyaltyInfoFetcher, {
        keepPreviousData: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryCount: 0,
        dedupingInterval: DEDUPING_INTERVAL
    }), data = ref1.data, isLoading = ref1.isLoading;
    return {
        data: data,
        isLoading: isLoading
    };
};
