import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { CookieKeys, sha256 } from "@finn/ui-utils";
import cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
var SavedPassHash = "8075cc17eaf2b1fd73e527dcdb3e914ac10f81f30e48a1dc32c7dfa87b6c3a1d";
export var useIsCosmicHelperEnabled = function() {
    var ref = useState(""), passHash = ref[0], setPassHash = ref[1];
    var query = useRouter().query;
    useEffect(function() {
        var generateHash = function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(val) {
                var hash;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return sha256(val);
                        case 2:
                            hash = _ctx.sent;
                            setPassHash(hash);
                        case 4:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function generateHash(val) {
                return _ref.apply(this, arguments);
            };
        }();
        var queryValue = query[CookieKeys.COSMIC_HELPER];
        if (queryValue) {
            cookies.set(CookieKeys.COSMIC_HELPER, queryValue);
            generateHash(queryValue);
        }
        var featureValue = cookies.get(CookieKeys.COSMIC_HELPER);
        if (featureValue) {
            generateHash(featureValue);
        }
    }, []);
    return passHash === SavedPassHash;
};
