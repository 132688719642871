import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
export var getFirstResponseThatFits = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(responsesPromises) {
        var response, results, secondaryResponse, status, isError;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return responsesPromises[0].request;
                case 2:
                    response = _ctx.sent;
                    if (!(response.status < 400)) {
                        _ctx.next = 6;
                        break;
                    }
                    // we cancel all other requests
                    responsesPromises.forEach(function(request) {
                        return request.cancel();
                    });
                    return _ctx.abrupt("return", {
                        data: response.data,
                        status: response.status
                    });
                case 6:
                    _ctx.next = 8;
                    return Promise.allSettled(responsesPromises.map(function(request) {
                        return request.request;
                    })).then(function(res) {
                        return _to_consumable_array(res.values());
                    }).catch(function() {
                        return [];
                    });
                case 8:
                    results = _ctx.sent;
                    secondaryResponse = results.find(function(result) {
                        var ref;
                        return (result === null || result === void 0 ? void 0 : (ref = result.value) === null || ref === void 0 ? void 0 : ref.status) < 400;
                    });
                    if (!secondaryResponse) {
                        _ctx.next = 13;
                        break;
                    }
                    // we cancel all other requests
                    responsesPromises.forEach(function(request) {
                        return request.cancel();
                    });
                    return _ctx.abrupt("return", {
                        data: secondaryResponse.value.data,
                        status: secondaryResponse.value.status
                    });
                case 13:
                    status = response.status || 500;
                    isError = status >= 400;
                    return _ctx.abrupt("return", {
                        data: isError ? null : response.data,
                        status: status
                    });
                case 16:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getFirstResponseThatFits(responsesPromises) {
        return _ref.apply(this, arguments);
    };
}();
