import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
// TODO: Remove `null` from fetcher.
// TODO: Disable the following rule in the ESLint config as it never works correctly.
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */ import { useDeals } from "@finn/platform-modules";
import { Features } from "@finn/ua-featureflags";
import { config, createFetcher, generateLocalizationHeaders, isServer, Locale } from "@finn/ui-utils";
import Cookies from "js-cookie";
import union from "lodash/union";
import useSWR from "swr/immutable";
import { FilterKey, PRICING_TYPE, VehicleViewKey } from "./constants";
var oldFetcher = createFetcher({
    baseURL: config.FLEET_API_URL,
    withCredentials: false
});
var fetcher = createFetcher({
    baseURL: config.PRODUCT_API_URL,
    withCredentials: false
});
/**
 * SWR doesnt allow us to switch fetcher directly, as it doesnt detect the change.
 * This work-around allows us to query once the correct API without re-rendering.
 */ var dynamicFetcher = function(request) {
    var locale = request.headers["X-Language-Tag"];
    if (locale === Locale.ENGLISH_USA) {
        return oldFetcher(request);
    } else {
        return fetcher(request);
    }
};
function makeGetFiltersPayload(param) {
    var filters = param.filters, locale = param.locale, zipcode = param.zipCode, _pricingType = param.pricingType, pricingType = _pricingType === void 0 ? PRICING_TYPE.DOWNPAYMENT : _pricingType;
    if (!filters.view) {
        filters.view = locale === Locale.ENGLISH_USA ? VehicleViewKey.AVAILABLE_AND_COMING_SOON : VehicleViewKey.AVAILABLE;
    }
    return {
        url: "/filters/cars",
        query: _object_spread_props(_object_spread({}, filters), {
            zipcode: zipcode,
            pricing_type: pricingType
        }),
        headers: generateLocalizationHeaders(locale)
    };
}
/**
 * Fetches and returns possible values for Fleet API cars' filters.
 *
 * Flow explained:
 *
 * 1. It fetches two sets of filters – one for the `available` view, and one for
 * the `displayed-cars` view. The reason behind this is the fact that `displayed-cars`
 * contains more data and is in fact a superset of the `available` filters.
 * We need to extract all possible brand values to make the filter URL parsing for
 * the out-of-stock brands and models possible.
 *
 * 2. It merges brands and models of both `displayed-cars` and `available` filters
 * and fixes the `available` property for both brands and models. All the brands and models
 * from the `available` view should remain being available, however there are other brands
 * in the `displayed-cars` view that come with `available: true`, which is incorrect since
 * they are not listed in the `available` view and have no available cars. There are also
 * models inside the available brands of the `displayed-cars` view that are unavailable in the
 * `available` view, but still come with `available: true`, which is incorrect by the same reason.
 * So while merging happens, the difference between `displayed-cars` and `available` views
 * is merged into the response from the `available` view with the property `available: false`.
 *
 * @param params
 * @returns possible filter values required for filter parsing and UI
 */ export function getFilters(_) {
    return _getFilters.apply(this, arguments);
}
function _getFilters() {
    _getFilters = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var filters, locale, zipCode, ref, availableFilters, displayedFilters, filtersResponse, availableBrandsMap, displayedBrandsMap, allBrandIds, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, brandId, availableBrand, displayedBrand, models;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    filters = param.filters, locale = param.locale, zipCode = param.zipCode;
                    _ctx.t0 = _sliced_to_array;
                    _ctx.next = 4;
                    return Promise.all([
                        dynamicFetcher(makeGetFiltersPayload({
                            filters: filters,
                            locale: locale,
                            zipCode: zipCode
                        })),
                        dynamicFetcher(makeGetFiltersPayload({
                            filters: _object_spread_props(_object_spread({}, filters), {
                                view: VehicleViewKey.DISPLAYED_CARS
                            }),
                            locale: locale,
                            zipCode: zipCode
                        })), 
                    ]);
                case 4:
                    _ctx.t1 = _ctx.sent;
                    ref = (0, _ctx.t0)(_ctx.t1, 2);
                    availableFilters = ref[0];
                    displayedFilters = ref[1];
                    filtersResponse = _object_spread_props(_object_spread({}, availableFilters), {
                        brands: [],
                        models: []
                    });
                    availableBrandsMap = new Map(availableFilters[FilterKey.BRANDS].map(function(brand) {
                        return [
                            brand.id,
                            brand
                        ];
                    }));
                    displayedBrandsMap = new Map(displayedFilters[FilterKey.BRANDS].map(function(brand) {
                        return [
                            brand.id,
                            brand
                        ];
                    }));
                    allBrandIds = union(displayedFilters[FilterKey.BRANDS].map(function(brand) {
                        return brand.id;
                    }), availableFilters[FilterKey.BRANDS].map(function(brand) {
                        return brand.id;
                    }));
                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    _ctx.prev = 13;
                    _iterator = allBrandIds[Symbol.iterator]();
                case 15:
                    if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                        _ctx.next = 27;
                        break;
                    }
                    brandId = _step.value;
                    availableBrand = availableBrandsMap.get(brandId);
                    displayedBrand = displayedBrandsMap.get(brandId);
                    if (availableBrand) {
                        _ctx.next = 22;
                        break;
                    }
                    filtersResponse.brands.push(_object_spread_props(_object_spread({}, displayedBrand), {
                        available: false,
                        models: displayedBrand.models.map(function(model) {
                            return _object_spread_props(_object_spread({}, model), {
                                available: false
                            });
                        })
                    }));
                    return _ctx.abrupt("continue", 24);
                case 22:
                    models = _to_consumable_array(displayedBrand.models.map(function(model) {
                        return _object_spread_props(_object_spread({}, model), {
                            available: false
                        });
                    })).concat(_to_consumable_array(availableBrand.models));
                    filtersResponse.brands.push(_object_spread_props(_object_spread({}, availableBrand), {
                        models: models
                    }));
                case 24:
                    _iteratorNormalCompletion = true;
                    _ctx.next = 15;
                    break;
                case 27:
                    _ctx.next = 33;
                    break;
                case 29:
                    _ctx.prev = 29;
                    _ctx.t2 = _ctx["catch"](13);
                    _didIteratorError = true;
                    _iteratorError = _ctx.t2;
                case 33:
                    _ctx.prev = 33;
                    _ctx.prev = 34;
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                case 36:
                    _ctx.prev = 36;
                    if (!_didIteratorError) {
                        _ctx.next = 39;
                        break;
                    }
                    throw _iteratorError;
                case 39:
                    return _ctx.finish(36);
                case 40:
                    return _ctx.finish(33);
                case 41:
                    filtersResponse[FilterKey.MODELS] = _to_consumable_array(displayedFilters[FilterKey.MODELS]).concat(_to_consumable_array(availableFilters[FilterKey.MODELS]));
                    return _ctx.abrupt("return", filtersResponse);
                case 43:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                13,
                29,
                33,
                41
            ],
            [
                34,
                ,
                36,
                40
            ]
        ]);
    }));
    return _getFilters.apply(this, arguments);
}
export var useGetFilters = function(params, isOutOfStock, swrConfig) {
    var deals = useDeals().deals;
    var hideDownpayment = !isServer() && Cookies.get("".concat(params.locale, "_").concat(Features.HideDownPayment)) === "b";
    if (!params.filters) {
        params.filters = {};
    }
    if (deals && deals.length > 0) {
        params.filters.view = VehicleViewKey.AVAILABLE_AND_COMING_SOON;
    } else if (isOutOfStock) {
        params.filters.view = VehicleViewKey.DISPLAYED_CARS;
    }
    params.pricingType = hideDownpayment ? PRICING_TYPE.NORMAL : PRICING_TYPE.DOWNPAYMENT;
    return useSWR(params && makeGetFiltersPayload(params), dynamicFetcher, swrConfig);
};
