import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
// https://github.com/finn-auto/finn-auto-app/blob/f3d46d09ba93e0822a01480d11aa09f7158ba16f/modules/WebApp/sdks/webSDKConnector.ts#L29
var sdkConnectorCode = '\n  const props = "{props}";\n  window.onWebReadyToConnectToApp = (cb) => {\n    cb(props);\n  };\n';
export var generateMockOfNativeSDK = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(screenName) {
        var propsToTrasfer, listenerCode;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    propsToTrasfer = {
                        screenName: screenName,
                        bottom: 0,
                        allowSamePageWebNavigation: true,
                        localState: "",
                        locale: "en-US",
                        platform: "ios",
                        version: "1.47.12",
                        buildNumber: "1",
                        codepushId: "",
                        deviceModel: "iphone 12",
                        deviceName: "device name",
                        deviceType: "ios",
                        deviceNetwork: "wifi",
                        os: "ios",
                        osVersion: "1.16.2",
                        screenWidth: 400,
                        screenHeight: 768,
                        features: [],
                        withWebPDPColorChange: true
                    };
                    listenerCode = "\n    window.ReactNativeWebView = {\n      postMessage: window.__test__onMessage\n    };\n  ";
                    return _ctx.abrupt("return", listenerCode + sdkConnectorCode.replace('"{props}"', JSON.stringify(propsToTrasfer)));
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function generateMockOfNativeSDK(screenName) {
        return _ref.apply(this, arguments);
    };
}();
export var setupNativeSDKMock = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param, page) {
        var screenName, onMessage;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    screenName = param.screenName, onMessage = param.onMessage;
                    _ctx.prev = 1;
                    _ctx.next = 4;
                    return page.exposeFunction("__test__onMessage", onMessage);
                case 4:
                    _ctx.next = 8;
                    break;
                case 6:
                    _ctx.prev = 6;
                    _ctx.t0 = _ctx["catch"](1);
                case 8:
                    _ctx.t1 = page;
                    _ctx.next = 11;
                    return generateMockOfNativeSDK(screenName);
                case 11:
                    _ctx.t2 = _ctx.sent;
                    _ctx.t3 = {
                        content: _ctx.t2
                    };
                    _ctx.next = 15;
                    return _ctx.t1.addInitScript.call(_ctx.t1, _ctx.t3);
                case 15:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                6
            ]
        ]);
    }));
    return function setupNativeSDKMock(_, page) {
        return _ref.apply(this, arguments);
    };
}();
