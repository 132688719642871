import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { fetchAuthToken } from "@finn/ua-auth";
import { useSession } from "@finn/ui-utils";
import { useCallback, useEffect, useState } from "react";
var AUTH_TOKEN_LIFETIME = Number(process.env.NEXT_PUBLIC_AUTH_TOKEN_LIFETIME) || 1000 * 60 * 10;
export var useBannerDeepLink = function(param) {
    var withAuth = param.withAuth, deepLink = param.deepLink, autohide = param.autohide;
    var ref = useState(""), bannerDeepLink = ref[0], setBannerDeepLink = ref[1];
    var ref1 = _sliced_to_array(useSession(), 2), session = ref1[0], isSessionLoading = ref1[1];
    var hideSmartBanner = useCallback(function() {
        var ref;
        (ref = window.AF) === null || ref === void 0 ? void 0 : ref.call(window, "banners", "hideBanner");
    }, []);
    useEffect(function() {
        var createDeepLink = function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
                var ref, fetchedToken;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if (!(withAuth && session && !isSessionLoading)) {
                                _ctx.next = 14;
                                break;
                            }
                            _ctx.prev = 1;
                            _ctx.next = 4;
                            return fetchAuthToken();
                        case 4:
                            ref = _ctx.sent;
                            fetchedToken = ref.authToken;
                            setBannerDeepLink(fetchedToken ? "".concat(deepLink, "/").concat(fetchedToken) : deepLink);
                            _ctx.next = 12;
                            break;
                        case 9:
                            _ctx.prev = 9;
                            _ctx.t0 = _ctx["catch"](1);
                            setBannerDeepLink(deepLink);
                        case 12:
                            _ctx.next = 15;
                            break;
                        case 14:
                            {
                                setBannerDeepLink(deepLink);
                            }
                        case 15:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee, null, [
                    [
                        1,
                        9
                    ]
                ]);
            }));
            return function createDeepLink() {
                return _ref.apply(this, arguments);
            };
        }();
        if (deepLink) {
            createDeepLink();
        }
    }, [
        withAuth,
        deepLink,
        session,
        isSessionLoading
    ]);
    useEffect(function() {
        if (!autohide) {
            return;
        }
        var timerId = setTimeout(function() {
            hideSmartBanner();
        }, AUTH_TOKEN_LIFETIME / 2);
        return function() {
            clearTimeout(timerId);
        };
    }, [
        autohide,
        hideSmartBanner
    ]);
    return {
        hideSmartBanner: hideSmartBanner,
        bannerDeepLink: bannerDeepLink
    };
};
