import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useEffect, useState } from "react";
import { denormalizeCosmicJSON } from "../../../scripts/prepare-cache/normalize";
export var fetchCache = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var rawCache, appCache, pages;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return import("./cache.json");
                case 2:
                    rawCache = _ctx.sent;
                    appCache = denormalizeCosmicJSON(rawCache);
                    pages = Object.values((appCache === null || appCache === void 0 ? void 0 : appCache.pages) || {});
                    return _ctx.abrupt("return", {
                        pages: pages.map(function(page) {
                            return {
                                slug: page === null || page === void 0 ? void 0 : page.slug,
                                locale: page === null || page === void 0 ? void 0 : page.locale
                            };
                        }),
                        pagesMapByLocale: pages.reduce(function(res, page) {
                            res[page === null || page === void 0 ? void 0 : page.locale] = _object_spread_props(_object_spread({}, res[page === null || page === void 0 ? void 0 : page.locale]), _define_property({}, page === null || page === void 0 ? void 0 : page.slug, page));
                            return res;
                        }, {}),
                        resources: rawCache.resources,
                        modules: appCache.modules
                    });
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchCache() {
        return _ref.apply(this, arguments);
    };
}();
export var useModuleDefaults = function(slug) {
    var locale = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "de-DE";
    var ref = useState(null), content = ref[0], setContent = ref[1];
    var ref1 = useState(true), isLoading = ref1[0], setIsLoading = ref1[1];
    // we load cache in async way to avoid large bundle size
    useEffect(function() {
        fetchCache().then(function(data) {
            setContent(Object.values(data.modules).find(function(module) {
                return (module === null || module === void 0 ? void 0 : module.slug) === slug && (locale ? (module === null || module === void 0 ? void 0 : module.locale) === locale : true);
            }));
            setIsLoading(false);
        }).catch(function() {
            setIsLoading(false);
        });
    }, [
        locale,
        slug
    ]);
    return [
        content,
        isLoading
    ];
};
