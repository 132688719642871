import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { config } from "@finn/ui-utils";
import Cosmic from "cosmicjs";
export var runCosmicRawQuery = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(query) {
        var fields, showMetaFields, api, bucket, data, response, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    fields = _args.length > 1 && _args[1] !== void 0 ? _args[1] : "content,metadata,locale", showMetaFields = _args.length > 2 && _args[2] !== void 0 ? _args[2] : false;
                    api = Cosmic();
                    bucket = api.bucket({
                        slug: "finnauto",
                        read_key: config.COSMIC_BUCKET_READ_KEY
                    });
                    data = [];
                    _ctx.prev = 4;
                    _ctx.next = 7;
                    return bucket.getObjects({
                        query: query,
                        props: fields,
                        show_metafields: showMetaFields
                    });
                case 7:
                    response = _ctx.sent;
                    data = response.objects;
                    _ctx.next = 14;
                    break;
                case 11:
                    _ctx.prev = 11;
                    _ctx.t0 = _ctx["catch"](4);
                    console.log(_ctx.t0);
                case 14:
                    return _ctx.abrupt("return", Array.isArray(data) ? data : []);
                case 15:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                4,
                11
            ]
        ]);
    }));
    return function runCosmicRawQuery(query) {
        return _ref.apply(this, arguments);
    };
}();
